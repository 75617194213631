<template>
    <div class="auth-wrapper auth-v1"
         style="padding-bottom: 0px; padding-top: 0px">
        <div v-if="dialog && list.length > 0">
            <DelPopup @delete="closeDialogPrivacy"
                      :selectedLanguage="selectedLanguage"
                      :modalPrivacy="dialog"></DelPopup>
        </div>

        <div v-if="showAlert && list.length > 0">
            <alert :message="alertMessage"
                   :type="alertType"></alert>
        </div>

        <div class="auth-inner main-container-width">
            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">
                    <v-card flat
                            class="full-min-height">
                        <app-header></app-header>
                        <v-col cols="12"
                               lg="12">
                            <v-row class="backbtn mt-4 mr-4">
                                <BackAction :text="$t('Back')"
                                            :output="false" />
                            </v-row>
                        </v-col>
                        <v-row class="mx-6 mt-5"
                               v-if="step < 4">
                            <v-col cols="12"
                                   class="px-0"
                                   lg="12">
                                <v-row class="mx-0 mb-2"
                                       v-if="step > 0">
                                    <BackAction :text="text"
                                                :output="output"
                                                @back="back" />
                                </v-row>
                                <v-card class="mx-5 mt-3 security"
                                        v-if="step == 0">
                                    <v-row class="mx-5 pa-2"
                                           v-if="list.length > 0">
                                        <div class="d-flex">
                                            <div>
                                                <v-card-text class="mt-10">
                                                    <p class="text-sm text-color-black font-weight-semibold"
                                                       style="font-size: 19px !important">
                                                        {{ $t('Trip Planner') }}
                                                    </p>
                                                </v-card-text>
                                            </div>
                                        </div>

                                        <v-row class="pa-2 ml-10">
                                            <v-col cols="12"
                                                   lg="3"
                                                   md="6"
                                                   sm="12">
                                                <v-card>
                                                    <v-card-text>
                                                        <div class="d-flex flex-column">
                                                            <div class="text-black font-weight-semibold"
                                                                 style="font-size: 1.875rem !important">
                                                                {{ active }}
                                                            </div>
                                                            <div class="text-blue font-weight-semibold"
                                                                 style="font-size: 13px !important">
                                                                {{ $t('Active Plan') }}
                                                            </div>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12"
                                                   lg="3"
                                                   md="6"
                                                   sm="12">
                                                <v-card>
                                                    <v-card-text>
                                                        <div class="d-flex flex-column">
                                                            <div class="text-black font-weight-semibold"
                                                                 style="font-size: 1.875rem !important">
                                                                {{ Inactive }}
                                                            </div>
                                                            <div class="text-blue font-weight-semibold"
                                                                 style="font-size: 13px !important">
                                                                {{ $t('Completed Plan') }}
                                                            </div>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col lg="6"
                                                   class="text-right trip-btn">
                                                <v-btn color="primary"
                                                       class="font-weight-bold"
                                                       @click="next">{{ $t('Add New Trip') }}</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>


                        <!-- First Step Load Trips -->
                        <v-stepper v-model="step"
                                   class="custom"
                                   style="background: transparent">
                            <v-stepper-items>
                                <v-stepper-content step="0">
                                    <v-card class="mx-5 pa-10 security"
                                            v-if="list.length > 0">
                                        <v-row class="mx-0">
                                            <v-col lg="6"
                                                   class="mt-0 px-0">
                                                <h2 class="text-black font-weight-semibold"
                                                    style="font-size: 14px">{{ $t('Your trips') }}</h2>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12"
                                                   lg="4"
                                                   md="6"
                                                   sm="12"
                                                   v-for="(item, idx) in list"
                                                   :key="idx">
                                                <v-card style="padding: 15px; border-radius: 11px !important"
                                                        tile
                                                        elevation="4"
                                                        class="">
                                                    <div class="d-flex flex-column">
                                                        <div class="text-black font-weight-semibold mb-1">
                                                            <span><img :src="item.flag"
                                                                     width="18px" /></span>
                                                            <span v-if="selectedLanguage == 'en'"
                                                                  class="mx-2">{{ item.Destination.Country }}</span>

                                                            <span v-if="selectedLanguage == 'ar'"
                                                                  class="mx-2">{{ item.arName }}</span>
                                                        </div>
                                                        <div class="text-sm mb-1"
                                                             style="color: #8a8a8a; display: flex">
                                                            <span>
                                                                <img src="@/assets/images/icons/Calendar-trip.png"
                                                                     width="14px"
                                                                     style="margin-top: 5px" /></span>
                                                            <span
                                                                  style="margin-top: 3px; font-size: 13px; margin-left: 13px; margin-right: 13px">

                                                                {{ getArabicDate(item.Destination.StartDate,
                                                                    selectedLanguage) }} -
                                                                {{ getArabicDate(item.Destination.EndDate, selectedLanguage)
                                                                }}
                                                            </span>
                                                        </div>

                                                        <div class="text-sm font-weight-regular"
                                                             style="color: #8a8a8a">
                                                            <span><img src="@/assets/images/icons/Cards.png"
                                                                     width="18px" /></span>
                                                            <span
                                                                  style="font-size: 13px; margin-left: 6px; margin-right: 6px">
                                                                {{ item.linkedCards }}

                                                                {{ $t('Linked Cards') }}</span>
                                                        </div>
                                                    </div>
                                                    <v-divider class="mt-3"></v-divider>
                                                    <div class="d-flex justify-space-between mt-2">
                                                        <div class="d-flex align-center"
                                                             @click="edit(item)"
                                                             style="cursor: pointer">
                                                            <img src="@/assets/images/icons/Edit.png" />
                                                            <span style="color: #006fcf; font-size: 13px; padding: 0px 4px">
                                                                {{ $t('Edit') }}
                                                            </span>
                                                        </div>
                                                        <div style="cursor: pointer"
                                                             @click="openPopup(item.Serno)"
                                                             class="d-flex align-center">
                                                            <img src="@/assets/images/icons/Trash.png" />
                                                            <span style="color: #006fcf; font-size: 13px; padding: 0px 4px">
                                                                {{ $t('Delete') }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </v-card>
                                            </v-col>

                                        </v-row>
                                    </v-card>
                                    <v-card v-if="list.length == 0 && isLoaded">
                                        <v-row>
                                            <v-col cols="12"
                                                   lg="12"
                                                   md="12"
                                                   sm="12">
                                                <v-row style="justify-content: center;"
                                                       :class="{ 'ml-11': selectedLanguage == 'en', 'mr-11': selectedLanguage == 'ar' }"
                                                       class="textdir">
                                                    <v-col class="mt-2 d-flex justify-center">
                                                        <v-img contain
                                                               max-width="150px"
                                                               :class="{ 'mr-14': selectedLanguage == 'en', 'ml-14': selectedLanguage == 'ar' }"
                                                               class="auth-3d-group overflow-hidden"
                                                               :src="require(`@/assets/images/Group.png`)">
                                                        </v-img>
                                                    </v-col>
                                                </v-row>

                                                <v-row style="justify-content:center">
                                                    <v-col cols="12"
                                                           lg="12"
                                                           md="12"
                                                           sm="12">
                                                        <p class="text-center">
                                                            <span style="font-size: 24px"
                                                                  class="mt-16 text-black font-weight-semibold">
                                                                {{ $t('Going abroad?') }}</span>
                                                        </p>
                                                        <p class="text-center">
                                                            <span style="font-size: 14px">
                                                                {{ $t(`If you are planning on using your Card(s),let us know
                                                                your trip details`)
                                                                }}</span>
                                                        </p>

                                                        <p class="text-center">
                                                            <span style="font-size: 14px"
                                                                  class="">
                                                                {{
                                                                    $t(`This will avoid interruption of your Card(s) while
                                                                travelling outside of
                                                                Saudi Arabia.`)
                                                                }}
                                                            </span>


                                                        </p>
                                                    </v-col>
                                                </v-row>

                                                <v-row style="justify-content: center;">
                                                    <v-col cols="12"
                                                           lg="3"
                                                           md="6"
                                                           sm="12"
                                                           class="mt-5">
                                                        <v-btn color="primary"
                                                               style="width:100%"
                                                               class="font-weight-bold"
                                                               @click="next">{{ $t('Add New Trip') }}</v-btn>
                                                    </v-col>
                                                </v-row>

                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>

                        <v-row v-if="(step > 0) & (step < 4)">
                            <v-col lg="7"
                                   md="12"
                                   sm="12"
                                   cols="12">
                                <v-stepper v-model="step"
                                           class="custom"
                                           style="background: transparent">
                                    <v-stepper-items>
                                        <v-stepper-content step="1">
                                            <v-row class="pa-0">
                                                <v-col cols="12"
                                                       lg="8"
                                                       md="12"
                                                       sm="12">
                                                    <v-row class="mx-3 mt-0"
                                                           justify="space-between">
                                                        <v-col cols="12"
                                                               lg="6"
                                                               md="12"
                                                               sm="12">
                                                            <h2 style="font-size: 21px">
                                                                {{ update ? $t('Update new trip') : $t('Add new trip') }}
                                                            </h2>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="mx-3 mt-0"
                                                           justify="space-between">
                                                        <v-col cols="12"
                                                               lg="12"
                                                               md="12"
                                                               sm="12">
                                                            <span style="font-size: 12px">
                                                                {{
                                                                    $t(`Let us know your destination country, Arrival and
                                                                leave date`)
                                                                }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>
                                                    <div class="mx-5 mt-4 container">
                                                        <v-row>
                                                            <v-col cols="12"
                                                                   lg="12"
                                                                   md="12"
                                                                   sm="12">
                                                                <div class="text-subtitle-1 text-medium-emphasis text-black mb-2"
                                                                     style="font-size: 15px !important">
                                                                    {{ $t('Select Country') }}
                                                                </div>
                                                                <v-select v-if="selectedLanguage == 'en'"
                                                                          :items="items"
                                                                          v-model="destCountry"
                                                                          filled
                                                                          item-value="name"
                                                                          item-text="name"
                                                                          :placeholder="$t('Select Country')"
                                                                          solo
                                                                          outlined></v-select>

                                                                <v-select v-if="selectedLanguage == 'ar'"
                                                                          :items="items"
                                                                          v-model="destCountry"
                                                                          item-value="name"
                                                                          item-text="name_ar"
                                                                          :placeholder="$t('Select Country')"
                                                                          filled
                                                                          solo
                                                                          outlined></v-select>
                                                            </v-col>
                                                        </v-row>

                                                        <v-row>
                                                            <v-col cols="12"
                                                                   lg="6"
                                                                   md="12"
                                                                   sm="12">
                                                                <div class="text-subtitle-1 text-medium-emphasis text-black mb-2"
                                                                     style="font-size: 15px !important">
                                                                    {{ $t(`From`) }}
                                                                </div>
                                                                <v-menu v-model="menu2"
                                                                        :close-on-content-click="false"
                                                                        :nudge-right="40"
                                                                        transition="scale-transition"
                                                                        offset-y
                                                                        min-width="auto">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-text-field v-model="date"
                                                                                      outlined
                                                                                      append-icon="mdi-calendar-month"
                                                                                      @click:append="on.click"
                                                                                      v-bind="attrs"
                                                                                      :placeholder="$t('From')"
                                                                                      v-on="on"></v-text-field>
                                                                    </template>
                                                                    <v-date-picker v-model="date"
                                                                                   @input="menu2 = false"
                                                                                   :min="minDate"></v-date-picker>
                                                                </v-menu>
                                                            </v-col>
                                                            <v-col cols="12"
                                                                   lg="6"
                                                                   md="12"
                                                                   sm="12">
                                                                <div class="text-subtitle-1 text-medium-emphasis text-black mb-2"
                                                                     style="font-size: 15px !important">
                                                                    {{ $t('To') }}
                                                                </div>
                                                                <v-menu v-model="menu3"
                                                                        :close-on-content-click="false"
                                                                        :nudge-right="40"
                                                                        transition="scale-transition"
                                                                        offset-y
                                                                        min-width="auto">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-text-field v-model="date1"
                                                                                      outlined
                                                                                      append-icon="mdi-calendar-month"
                                                                                      @click:append="on.click"
                                                                                      :placeholder="$t('To')"
                                                                                      v-bind="attrs"
                                                                                      v-on="on"></v-text-field>
                                                                    </template>
                                                                    <v-date-picker v-model="date1"
                                                                                   :min="date"
                                                                                   @input="menu3 = false"></v-date-picker>
                                                                </v-menu>
                                                            </v-col>
                                                        </v-row>

                                                        <v-row>
                                                            <v-col lg="12">
                                                                <v-btn block
                                                                       color="primary"
                                                                       type="submit"
                                                                       @click="next"
                                                                       large>
                                                                    {{ $t('Next') }}
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-stepper-content>

                                        <v-stepper-content step="2">
                                            <v-row class="pa-0">
                                                <v-col>
                                                    <v-row class="mx-3"
                                                           justify="space-between">
                                                        <v-col cols="12"
                                                               lg="6"
                                                               md="12"
                                                               sm="12"
                                                               class="pb-0">
                                                            <h2 style="font-size: 21px"
                                                                class="text-color-black">{{ $t('Choose cards') }}</h2>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="mx-3">
                                                        <v-col lg="12"
                                                               class="pt-0">
                                                            <span style="font-size: 14px">
                                                                {{
                                                                    $t(`You can add more than one card that you want to use
                                                                during your trip.`)
                                                                }}
                                                            </span>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col lg="10">
                                                            <div class="mx-5 mt-4 container security"
                                                                 style="padding-bottom: 0px">
                                                                <v-container v-for="(card, idx) in listCards"
                                                                             :key="idx">
                                                                    <v-container v-for="(item, inx) in card"
                                                                                 :key="inx">
                                                                        <v-row class="pa-0 pb-0"
                                                                               v-if="item.isPrimary">
                                                                            <v-col lg="2"
                                                                                   md="2"
                                                                                   sm="2"
                                                                                   cols="4">
                                                                                <img :src="item.image"
                                                                                     style="height: 32px" />
                                                                            </v-col>
                                                                            <v-col lg="10"
                                                                                   md="10"
                                                                                   sm="10"
                                                                                   cols="8"
                                                                                   class="text-black mt-1 px-0"
                                                                                   style="font-size: 13px">
                                                                                <span v-if="selectedLanguage == 'ar'">{{
                                                                                    item.cardNameAr }}</span>
                                                                                <span v-if="selectedLanguage == 'en'">
                                                                                    {{ item.cardName }}</span>
                                                                            </v-col>
                                                                        </v-row>

                                                                        <v-row class="pa-0">
                                                                            <v-col lg="12"
                                                                                   class="pt-0">
                                                                                <v-card style="padding: 7px 19px">
                                                                                    <v-card-text style="padding: 0px">
                                                                                        <div
                                                                                             class="d-flex justify-space-between">
                                                                                            <div class="d-flex flex-column">
                                                                                                <div class="text-black font-weight-semibold"
                                                                                                     style="font-size: 13px">
                                                                                                    {{ item.cardMemberName
                                                                                                    }}
                                                                                                </div>
                                                                                                <div class="text-sm font-weight-regular"
                                                                                                     style="color: #8a8a8a; font-size: 13px">
                                                                                                    {{ $t('Card ending -')
                                                                                                    }}
                                                                                                    {{ item.Last4Digits
                                                                                                    }}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class=""
                                                                                                 style="margin-top: -13px">
                                                                                                <v-checkbox v-model="item.selected"
                                                                                                            style="left: -15px; height: 32px"
                                                                                                            @change="selectCard($event, item)"></v-checkbox>
                                                                                            </div>
                                                                                        </div>
                                                                                    </v-card-text>
                                                                                </v-card>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-container>
                                                                </v-container>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="mx-2 px-0">
                                                        <v-col lg="10">
                                                            <v-btn block
                                                                   color="primary"
                                                                   type="submit"
                                                                   @click="next"
                                                                   large>
                                                                {{ $t('Next') }}
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-stepper-content>

                                        <v-stepper-content step="3">
                                            <v-row class="pa-3">
                                                <v-col>
                                                    <v-row class="mx-3 mt-3"
                                                           justify="space-between">
                                                        <v-col cols="12"
                                                               lg="6"
                                                               md="12"
                                                               sm="12">
                                                            <h2 style="font-size: 21px">{{ $t('Review details') }}</h2>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="mx-3 mt-3">
                                                        <v-col lg="12">
                                                            <span style="font-size: 14px">{{ $t('Trip Details') }}</span>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col lg="10"
                                                               md="12"
                                                               sm="12"
                                                               cols="12">
                                                            <div class="mx-5 mt-2 container security">
                                                                <v-row class="pa-2">
                                                                    <v-col lg="12">
                                                                        <v-card>
                                                                            <v-card-text>
                                                                                <div class="d-flex flex-column">
                                                                                    <span>
                                                                                        {{ $t('Country') }}
                                                                                    </span>
                                                                                    <span
                                                                                          class="text-black font-weight-semibold">
                                                                                        {{ getArabicCountry(destCountry,
                                                                                            selectedLanguage) }}
                                                                                    </span>
                                                                                </div>
                                                                                <div class="d-flex flex-column mt-4">
                                                                                    <span>
                                                                                        {{ $t('Trip start date') }}
                                                                                    </span>
                                                                                    <span
                                                                                          class="text-black font-weight-semibold">
                                                                                        {{
                                                                                            getArabicDate(date,
                                                                                                selectedLanguage)
                                                                                        }}
                                                                                    </span>
                                                                                </div>
                                                                                <div class="d-flex flex-column mt-4">
                                                                                    <span>
                                                                                        {{ $t('Trip end date') }}
                                                                                    </span>
                                                                                    <span
                                                                                          class="text-black font-weight-semibold">
                                                                                        {{
                                                                                            getArabicDate(date1,
                                                                                                selectedLanguage)
                                                                                        }}
                                                                                    </span>
                                                                                </div>
                                                                            </v-card-text>
                                                                        </v-card>
                                                                    </v-col>
                                                                </v-row>

                                                                <div class="d-flex justify-space-between mt-2 mx-2">
                                                                    <span class="text-color-black">
                                                                        {{ $t('Linked cards') }}
                                                                    </span>
                                                                    <span @click="addCard"
                                                                          style="cursor: pointer"
                                                                          class="text-blue">
                                                                        <v-icon>mdi-plus</v-icon>
                                                                        {{ $t('Add Card') }}
                                                                    </span>
                                                                </div>

                                                                <v-row class="mt-3 pa-1">
                                                                    <v-col lg="12"
                                                                           v-for="(item, okx) in selectedCards"
                                                                           :key="okx">
                                                                        <v-card>
                                                                            <v-card-text>
                                                                                <div class="d-flex justify-space-between">
                                                                                    <div class="d-flex">
                                                                                        <div class="d-flex flex-column">
                                                                                            <img :src="item.image"
                                                                                                 style="height: 32px" />
                                                                                        </div>

                                                                                        <div
                                                                                             class="mx-5 d-flex flex-column">
                                                                                            <div class="text-black font-weight-semibold"
                                                                                                 style="font-size: 13px">
                                                                                                {{ item.CardMemberName }}
                                                                                            </div>
                                                                                            <div class="text-sm font-weight-regular"
                                                                                                 style="color: #8a8a8a; font-size: 13px">
                                                                                                {{ $t('Card ending -') }} {{
                                                                                                    item.Last4Digits }}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </v-card-text>
                                                                        </v-card>
                                                                    </v-col>
                                                                </v-row>

                                                                <div></div>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="mx-2">
                                                        <v-col lg="10">
                                                            <v-btn block
                                                                   color="primary"
                                                                   type="submit"
                                                                   @click="submitTrip"
                                                                   large>
                                                                {{ $t('Confirm and Save') }}
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-stepper-content>
                                    </v-stepper-items>
                                </v-stepper>
                            </v-col>

                            <!-- Divider -->
                            <v-col lg="1"
                                   class="line-section">
                                <v-row class="mt-12">
                                    <v-col style="height: 350px">
                                        <v-divider class="my-16"
                                                   :vertical="true"></v-divider>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <!-- Going Aboard Card -->
                            <v-col cols="12"
                                   lg="4"
                                   md="12"
                                   sm="12"
                                   class="bottom-section">
                                <v-row :class="{ 'mr-12': selectedLanguage == 'en', 'ml-12': selectedLanguage == 'ar' }"
                                       class="mt-16 textdir">
                                    <v-col class="mt-2 d-flex justify-center">
                                        <v-img contain
                                               max-width="150px"
                                               class="auth-3d-group overflow-hidden"
                                               :src="require(`@/assets/images/Group.png`)">
                                        </v-img>
                                    </v-col>
                                </v-row>

                                <p :class="{ 'mr-14': selectedLanguage == 'en', 'ml-14': selectedLanguage == 'ar' }"
                                   class="text-center">
                                    <span style="font-size: 24px"
                                          class="mt-16 text-black font-weight-semibold">
                                        {{ $t('Going abroad?') }}</span>
                                </p>
                                <p :class="{ 'mr-14': selectedLanguage == 'en', 'ml-14': selectedLanguage == 'ar' }"
                                   class="text-center">
                                    <span style="font-size: 14px">
                                        {{ $t('If you are planning on using your Card(s),let us know your trip details')
                                        }}</span>
                                </p>

                                <p :class="{ 'mr-14': selectedLanguage == 'en', 'ml-14': selectedLanguage == 'ar' }"
                                   class="text-center">
                                    <span style="font-size: 14px"
                                          class="">
                                        {{
                                            $t(`This will avoid interruption of your Card(s) while travelling outside of
                                        Saudi Arabia.`)
                                        }}
                                    </span>
                                </p>
                            </v-col>
                        </v-row>

                        <!-- Last Step Finished -->
                        <v-row class="mt-4"
                               v-if="step == 4">
                            <v-col lg="12">
                                <div class="mt-2 container">
                                    <v-row>
                                        <v-col lg="12">
                                            <v-card-text class="mx-10 d-flex justify-center">
                                                <div class="mx-10 d-flex flex-column"
                                                     style="align-items: center">
                                                    <v-img contain
                                                           max-width="160px"
                                                           class="auth-3d-group overflow-hidden"
                                                           :src="require(`@/assets/images/Group.png`)">
                                                    </v-img>
                                                    <span style="font-size: 25px"
                                                          class="mt-10 text-black font-weight-semibold">
                                                        {{ $t('Trip Planned') }}
                                                    </span>

                                                    <span style="font-size: 14px"
                                                          class="mt-5 text-black">
                                                        {{
                                                            !update ? $t(`All set! Your trip has been saved. Thanks for letting
                                                        us
                                                        know.`) : $t(`All set! Your trip has been updated. Thanks for
                                                        letting us
                                                        know.`)
                                                        }}
                                                    </span>

                                                    <v-btn class="mt-10"
                                                           block
                                                           color="primary"
                                                           @click="home"
                                                           type="submit">
                                                        {{ $t('Done') }}
                                                    </v-btn>
                                                </div>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import AppHeader from '../test/AppHeader.vue'
import soapServices from '@/services/soap-services'
import country from '../../@core/utils/country'
import DelPopup from './DelPopup.vue'
import store from '@/store'
import arabicDate from '@/@core/utils/arabicDate';
import moment from 'moment'
import { DeviceUUID } from 'device-uuid'
import sharedServices from '@/services/shared-services'
import alert from '@/views/alert.vue'
import BackAction from '@/@core/BackAction.vue'
import soapErrorMessages from '@/@core/utils/soap-error-messages';

import { getAnalytics, logEvent } from "firebase/analytics";

export default {
    components: {
        AppHeader,
        alert,
        DelPopup,
        BackAction,
    },

    data: () => ({
        analytics: getAnalytics(),
        step: 0,
        radioGroup: 0,
        active: 0,
        dialog: false,
        Inactive: 0,
        update: false,
        selectedCards: [],
        StartDate: '',
        EndDate: '',
        serNo: '0',
        tripSerNo: '0',
        alertMessage: '',
        alertType: 'error',
        showAlert: false,
        checked: true,
        items: country.getCountry(),
        allCards: [],
        accountCards: store.get('cardStore/accountCards'),
        listCards: store.get('cardStore/accountCards'),
        list: [],
        cards: [],
        destCountry: '',
        date: null,
        date1: null,
        minDate: new Date().toISOString().substr(0, 10),
        menu: false,
        menu2: false,
        menu3: false,
        selectedLanguage: 'en',
        text: 'Back To Trip',
        output: true,
        isLoaded: false,
    }),
    created()
    {
        store.subscribe(mutation =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    mounted()
    {
        let lang = localStorage.getItem('language')
        if (lang == 'ar')
        {
            this.selectedLanguage = lang;
        }
        else
        {
            this.selectedLanguage = lang;
        }
        this.listCards.forEach(element =>
        {
            element.forEach(card =>
            {
                card['selected'] = false
            })
        })

        this.list = [];

        this.getTrips();
    },

    methods: {
        addCard()
        {
            this.step = this.step - 1;
            // this.selectedCards = [];
        },
        getArabicDate(dateTime, lang)
        {
            return arabicDate.get(dateTime, lang, true)
        },
        getArabicCountry(C, lang)
        {
            return country.getArabicCountry(C, lang)
        },
        edit(item)
        {
            window.scrollTo(0, 0);
            this.update = true;
            this.selectedCards = [];

            this.step = 1;
            this.date = moment(item.Destination.StartDate).format('yyyy-MM-DD');
            this.date1 = moment(item.Destination.EndDate).format('yyyy-MM-DD');

            this.destCountry = item.Destination.Country;
            this.serNo = item.Destination.Serno;
            this.tripSerNo = item.Serno;

            this.listCards.forEach(aCards =>
            {
                aCards.forEach(card =>
                {
                    item.cards.forEach(tripCard =>
                    {
                        if (card.primeCardSerno == tripCard.PrimeCardSerno)
                        {
                            let serno = '0';
                            if (tripCard.Serno)
                            {
                                serno = tripCard.Serno;
                            }
                            card['selected'] = true;
                            card['Serno'] = serno;

                            this.selectedCards.push({
                                CardMemberName: card.cardMemberName,
                                Last4Digits: card.Last4Digits,
                                primeCardSerno: card.primeCardSerno,
                                AccountProductCode: card.productCodeTemp,
                                Product: card.product,
                                Serno: serno,
                                selected: true,
                                image: card.image,
                            })
                        }
                    });
                })
            })
        },
        getTrips()
        {
            this.list = [];
            this.isLoaded = false;

            let obj = {
                idSeed: sharedServices.getIdSeed(),
                sysId: new DeviceUUID().get(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysAuth: store.get('requestKeys/accessToken'),
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                requestorID: store.get('requestKeys/userName'),
                clientCode: store.get('requestKeys/ClientCode'),
            }

            soapServices.getTrip(obj).then(soapResp =>
            {
                if (soapResp?.Result?.ErrorCode === '000')
                {
                    if (Array.isArray(soapResp.Body.Plans.Plan))
                    {
                        this.list = soapResp.Body.Plans.Plan;
                    }
                    else
                    {
                        this.list.push(soapResp.Body.Plans.Plan);
                    }

                    this.active = 0;
                    this.Inactive = 0;

                    this.list.forEach(element =>
                    {
                        if (Array.isArray(element.Destination))
                        {
                            element.Destination = element.Destination[1];
                        }

                        element.Destination.StartDate = moment(element.Destination.StartDate).format('DD MMM yyyy');
                        element.Destination.EndDate = moment(element.Destination.EndDate).format('DD MMM yyyy');

                        element['flag'] = country.getSingleCountry(element.Destination.Country);
                        element['arName'] = country.getArCountry(element.Destination.Country);

                        if (new Date(element.Destination.EndDate) > new Date())
                        {
                            this.active++
                        }
                        else
                        {
                            this.Inactive++
                        }

                        if (Array.isArray(element.Card))
                        {
                            element['linkedCards'] = element.Card.length
                            element['cards'] = element.Card;
                        }
                        else
                        {
                            element['linkedCards'] = 1;
                            element['cards'] = [];
                            element['cards'].push(element.Card);
                        }
                    });

                    this.isLoaded = true;
                }
                else
                {
                    this.isLoaded = true;
                    this.list = [];
                }
            })
        },
        home()
        {
            this.step = 0
            this.selectedCards = []
            window.scrollTo(0, 0);
            this.getTrips()
        },
        back()
        {
            this.step = this.step - 1;
            window.scrollTo(0, 0);
        },
        openPopup(value)
        {
            this.serNo = value
            if (this.dialog == false)
            {
                this.dialog = true
            } else
            {
                this.dialog = false
            }
        },
        closeDialogPrivacy(event)
        {
            if (event)
            {
                this.deleteItem(this.serNo)
            }
            this.dialog = false
        },

        next()
        {
            window.scrollTo(0, 0);
            this.step++;

            if (this.step == 1)
            {
                this.update = false;
                this.date = '';
                this.date1 = '';
                this.destCountry = '';
                this.serNo = '0';
                this.tripSerNo = '0';
                this.listCards.forEach(element =>
                {
                    element.forEach(card =>
                    {
                        card['selected'] = false;
                    });
                });
            }
            if (this.step == 3)
            {
                let plan = [
                    {
                        Country: this.destCountry,
                        StartDate: moment(this.date).format('yyyy-MM-DD') + 'T' + moment(this.date).format('HH:mm:ss') + 'Z',
                        EndDate: moment(this.date1).format('yyyy-MM-DD') + 'T' + moment(this.date1).format('HH:mm:ss') + 'Z',
                        Serno: this.serNo,
                    },
                    { cardPlan: this.selectedCards },
                ]

                store.set('cardStore/tripPlan', plan)
            }
        },
        selectCard(event, card)
        {
            if (event == true)
            {
                let ser = '0';
                if (card.Serno)
                {
                    ser = card.Serno;
                }
                this.selectedCards.push({
                    CardMemberName: card.cardMemberName,
                    Last4Digits: card.Last4Digits,
                    primeCardSerno: card.primeCardSerno,
                    AccountProductCode: card.productCodeTemp,
                    Product: card.product,
                    Serno: ser,
                    selected: true,
                    image: card.image,
                })
            }
            else
            {
                for (let i = 0; i < this.selectedCards.length; i++)
                {
                    if (card.Last4Digits == this.selectedCards[i].Last4Digits)
                    {
                        this.selectedCards.splice(i, 1)
                        return
                    }
                }
            }
        },

        addNew()
        {
            let cards = store.get('cardStore/tripPlan')

            let obj = {
                idSeed: sharedServices.getIdSeed(),
                sysId: new DeviceUUID().get(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysAuth: store.get('requestKeys/accessToken'),
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                requestorID: store.get('requestKeys/userName'),
                clientCode: store.get('requestKeys/ClientCode'),
                plan: cards,
                tripSerNo: this.tripSerNo,
            }
            soapServices.addNewTrip(obj).then(soapResp =>
            {
                if (soapResp.AddPlanResponse.AddPlanResult.Root.Result.ErrorCode == '000')
                {
                    logEvent(this.analytics, 'AMEX_CI_TRIP_ADD_SUCCESS', {
                        content_type: 'userName',
                        content_id: store.get('requestKeys/userName')
                    })
                    this.step = 4
                    window.scrollTo(0, 0)
                } else
                {
                    logEvent(this.analytics, 'AMEX_CI_TRIP_ADD_FAILURE', {
                        content_type: 'userName',
                        content_id: store.get('requestKeys/userName')
                    })
                    let alertMessage = soapResp.AddPlanResponse.AddPlanResult.Root.Result.ErrorDesc
                    let errorCode = soapResp.AddPlanResponse.AddPlanResult.Root.Result?.ErrorCode
                    this.alertMessage = alertMessage
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            })
        },
        updateTrip()
        {
            let cards = store.get('cardStore/tripPlan')

            let obj = {
                idSeed: sharedServices.getIdSeed(),
                sysId: new DeviceUUID().get(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysAuth: store.get('requestKeys/accessToken'),
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                requestorID: store.get('requestKeys/userName'),
                clientCode: store.get('requestKeys/ClientCode'),
                plan: cards,
                tripSerNo: this.tripSerNo,
            }
            soapServices.updateTrip(obj).then(soapResp =>
            {
                if (soapResp.UpdatePlanResponse.UpdatePlanResult.Root.Result.ErrorCode == '000')
                {
                    this.step = 4
                    window.scrollTo(0, 0)
                } else
                {
                    let alertMessage = soapResp.UpdatePlanResponse.UpdatePlanResult.Root.Result.ErrorDesc
                    let errorCode = soapResp.UpdatePlanResponse.UpdatePlanResult.Root.Result?.ErrorCode
                    this.alertMessage = alertMessage
                    this.alertType = 'error'
                    this.showAlert = true
                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            })
        },
        submitTrip()
        {
            if (this.update)
            {
                this.updateTrip()
            }
            else
            {
                this.addNew()
            }
        },
        deleteItem(Serno)
        {
            let obj = {
                idSeed: sharedServices.getIdSeed(),
                sysId: new DeviceUUID().get(),
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysAuth: store.get('requestKeys/accessToken'),
                correlationID: Math.floor(1000 + Math.random() * 9000).toString(),
                requestorID: store.get('requestKeys/userName'),
                clientCode: store.get('requestKeys/ClientCode'),
                serno: Serno,
                plan: store.get('card/cardPlan'),
            }
            soapServices.removeTrip(obj).then(soapResp =>
            {
                if (soapResp.RemovePlanResponse.RemovePlanResult.Root.Result.ErrorCode == '000')
                {
                    window.scrollTo(0, 0);
                    this.getTrips();
                }
                else
                {
                    let alertMessage = soapResp.RemovePlanResponse.RemovePlanResult.Root.Result.ErrorDesc;
                    let errorCode = soapResp.RemovePlanResponse.RemovePlanResult.Root.Result?.ErrorCode;
                    this.alertMessage = soapErrorMessages.get(errorCode, alertMessage);
                    this.alertType = 'error';
                    this.showAlert = true;

                    setTimeout(() =>
                    {
                        this.showAlert = false
                    }, 5000)
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

// .textdir {
//     margin: 0px -43px 0px;
// }
.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    /* box-shadow: 0 2px 10px 0 rgb(94 86 105 / 10%); */
    box-shadow: none;
}

.v-application.theme--light .v-btn--is-elevated {
    /* box-shadow: 0 4px 8px -4px rgb(94 86 105 / 42%) !important; */
    box-shadow: none;
}

::v-deep .v-sheet.v-card[data-v-6d514de3]:not(.v-sheet--outlined) {
    /* box-shadow: 0 2px 10px 0 rgb(94 86 105 / 10%); */
    box-shadow: none !important;
}

::v-deep .v-application.theme--light .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    /* box-shadow: 0 1px 3px 0 rgb(94 86 105 / 20%), 0 3px 1px -2px rgb(94 86 105 / 12%), 0 2px 2px 0 rgb(94 86 105 / 14%); */
    box-shadow: none !important;
}

.trip-btn {
    display: flex;
    align-items: center;
    justify-content: end
}

@media screen and (max-width: 1259px) {

    .ml-10 {
        margin-left: -29px !important;
    }

    .trip-btn {
        display: flex;
        align-items: center;
        justify-content: center
    }

}

@media screen and (max-width: 1092px) {

    .mx-5 {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .line-section {
        display: none;
    }

    .mt-16 {
        margin-top: 5px !important;
    }

    .bottom-section {
        padding-left: 74px;

    }
}

@media screen and (max-width: 600px) {

    .mx-10 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

}
</style>
