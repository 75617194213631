<template>
    <!-- <div v-if="modalPrivacy"> -->
    <v-dialog v-model="modalPrivacy">
        <template>
            <!-- <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn> -->

        </template>
        <v-card class="v-dialog-logout">
            <v-card-title>
                <span @click="close(false)"
                      style="position: absolute; right: 10px;cursor: pointer;">
                    <v-icon>
                        {{ icons.mdiClose }}
                    </v-icon>
                </span>
            </v-card-title>


            <v-card-text>
                <div class="text-center">
                    <div class="secure-img">
                        <img src="@/assets/images/logos/account-detail.png"
                             alt="">
                    </div>
                    <p class="mt-3 text-lg">
                        {{ $t('Are you sure you want to Delete?') }}</p>
                </div>
            </v-card-text>

            <div class="pb-5 text-center">
                <v-btn @click="close(true)"
                       color="primary"
                       type="submit"
                       large
                       class="">
                    {{ $t('Delete') }}
                </v-btn>
                <v-btn type="submit"
                       large
                       :class="{ 'ml-3': selectedLanguage == 'en', 'mr-3': selectedLanguage == 'ar' }"
                       style="border: 1px solid #016FD0;"
                       @click="dialog = close(false)"
                       >
                    {{ $t('Cancel') }}
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
    <!-- </div> -->
</template>
  
<script>
import
{
    mdiClose
} from '@mdi/js'
export default {
    props: {
        modalPrivacy: {
            default: false,
            type: Boolean
        },
        selectedLanguage: {
            default: 'en',
            type: String
        }
    },
    data: () => ({
        icons: { mdiClose }
    }),

    mounted()
    {
    },

    methods: {
        close(val)
        {
            this.$emit('delete', val);
            // return this.modalPrivacy = !this.modalPrivacy;
        }
    }
}
</script>
  
<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.secure-img {
    background: #E2F1FD;
    width: 100px;
    height: 100px;
    padding-top: 22px;
    border-radius: 79px;
    margin: 0 auto;
}

.v-dialog {
    box-shadow: none !important;
}

.v-dialog-logout {
    min-width: 250px !important;
    max-width: 500px !important;
    margin: 0 auto;
}
</style>
  